import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Post from "../components/post"

export default class BlogPostList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout pageContext={this.props.pageContext}>
        {posts.map(({ node }) => (<Post post={node} />))}
      </Layout>
    )
  }
}

export const blogPostListQuery = graphql`
  query blogPostListQuery($skip: Int!, $limit: Int!){
    allMarkdownRemark(
      filter: {
        frontmatter: { visibility: { eq: "visible" } }
      }
      sort: { fields: [frontmatter___publication_date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            display_date
            publication_date
            category
            source_for_images_in_development
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`
