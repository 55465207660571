import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default class BlogCategories extends React.Component {
  render() {
    const categories = this.props.data.allMarkdownRemark.group
    return (
      <Layout pageContext={this.props.pageContext}>
        <ul>
        {categories.map((node) => (
            <li>
              <Link to={`/${node.fieldValue}`}>
                {node.fieldValue}
              </Link>
            </li>
        ))}
        </ul>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {category: {ne: ""}, visibility: { eq: "visible" }}}) {
      group(field: frontmatter___category) {
        totalCount
        fieldValue
      }
    }
  }
`
