const PROD_ENV = (process.env.NODE_ENV === 'production')
const PROD_URL_PREFIX = '//media.guillaume-et-laetitia.eu'
const PROD_ALT_TEXT = 'Image non chargée : cliquer pour afficher l\'image dans un nouvel onglet'
const DEV_URL_PREFIX = ''
const DEV_ALT_TEXT = 'Si vous voyez ceci, vous avez probablement oublier d\'enlever l\'attribut source_for_images_in_development: "remote" dans cet article'
const PROD_M_SIZE_IMAGE_URL_SUFFIX = '_m'
const PROD_S_SIZE_IMAGE_URL_SUFFIX = '_s'
const DEV_M_SIZE_IMAGE_URL_SUFFIX = ''
const DEV_S_SIZE_IMAGE_URL_SUFFIX = ''
const ONE_MEDIUM_SIZE_IMAGE_TOKEN_GLOBAL_REGEXP = /\[(\S+):m\]/g
const TWO_SMALL_SIZE_IMAGE_TOKENS_GLOBAL_REGEXP = /\[(\S+):s\]\n\[(\S+):s\]/g
const THREE_SMALL_SIZE_IMAGE_TOKENS_GLOBAL_REGEXP = /\[(\S+):s\]\n\[(\S+):s\]\n\[(\S+):s\]/g

function imageTags(imageName, size, slug, urlPrefix) {
  const imagePath = (urlPrefix === PROD_URL_PREFIX) ? slug : '/'
  const fullSizeImageUrl = `${urlPrefix}${imagePath}${imageName}.jpg`
  const m_size_url_suffix = (urlPrefix === PROD_URL_PREFIX) ? PROD_M_SIZE_IMAGE_URL_SUFFIX : DEV_M_SIZE_IMAGE_URL_SUFFIX
  const s_size_url_suffix = (urlPrefix === PROD_URL_PREFIX) ? PROD_S_SIZE_IMAGE_URL_SUFFIX : DEV_S_SIZE_IMAGE_URL_SUFFIX
  const smallerSizeImageUrlSuffix = (size === 'm') ? m_size_url_suffix : s_size_url_suffix
  const smallerSizeImageUrl = `${urlPrefix}${imagePath}${imageName}${smallerSizeImageUrlSuffix}.jpg`
  const width = (size === 'm') ? 640 : 320
  const altText = (urlPrefix === PROD_URL_PREFIX) ? PROD_ALT_TEXT : DEV_ALT_TEXT
  return `
    <a href="${fullSizeImageUrl}" target="_blank">
      <img
        alt="${altText}"
        src="${smallerSizeImageUrl}"
        width="${width}"
        referrerpolicy="no-referrer"
        loading="lazy"
      />
    </a>
  `;
}

function wrapInDivWithTextAlignCenter(content) {
  return `
  <div style="text-align: center">
    ${content}
  </div>
  `;
}

function oneMediumSizeImageTags(slug, urlPrefix) {
  return function(_match, imageName) {
    return wrapInDivWithTextAlignCenter(imageTags(imageName, 'm', slug, urlPrefix))
  }
}

function twoSmallSizeImageTags(slug, urlPrefix) {
  return function(_match, imageName1, imageName2) {
    return wrapInDivWithTextAlignCenter(`
      ${imageTags(imageName1, 's', slug, urlPrefix)}
      ${imageTags(imageName2, 's', slug, urlPrefix)}
    `);
  }
}

function threeSmallSizeImageTags(slug, urlPrefix) {
  return function(_match, imageName1, imageName2, imageName3) {
    return wrapInDivWithTextAlignCenter(`
      ${imageTags(imageName1, 's', slug, urlPrefix)}
      ${imageTags(imageName2, 's', slug, urlPrefix)}
      ${imageTags(imageName3, 's', slug, urlPrefix)}
    `);
  }
}

export default function formatHtmlHandlingImageTokens(htmlText, slug, sourceForImagesInDevelopment) {
  let urlPrefix
  if (PROD_ENV || sourceForImagesInDevelopment !== 'local') {
    urlPrefix = PROD_URL_PREFIX
  } else {
    urlPrefix = DEV_URL_PREFIX
  }

  return htmlText
    .replaceAll(
      ONE_MEDIUM_SIZE_IMAGE_TOKEN_GLOBAL_REGEXP,
      oneMediumSizeImageTags(slug, urlPrefix)
    )
    .replaceAll(
      THREE_SMALL_SIZE_IMAGE_TOKENS_GLOBAL_REGEXP,
      threeSmallSizeImageTags(slug, urlPrefix)
    )
    .replaceAll(
      TWO_SMALL_SIZE_IMAGE_TOKENS_GLOBAL_REGEXP,
      twoSmallSizeImageTags(slug, urlPrefix)
    )
}