import React from "react"
import { Link } from "gatsby"

import formatHtmlHandlingImageTokens from "../lib/imageTokenConverter"

import * as blogPostStyle from "./blog-post.module.css"

export default class Post extends React.Component {
  render() {
    const {
      id,
      frontmatter: {
        title,
        category,
        display_date: displayDate,
        publication_date: publicationDate,
        source_for_images_in_development: sourceForImagesInDevelopment
      },
      fields: {
        slug
      },
      html
    } = this.props.post
    return (
      <div key={id} className={blogPostStyle.post}>
        <Link to={slug}>
          <h3>{title}</h3>
        </Link>
        <span className={blogPostStyle.details}>
          &#9432;&nbsp;
          {this.displayAndPublicationDates({displayDate, publicationDate})}
          dans la catégorie <a
            href={this.formatCategoryAsUrl(category)}
            title="Cliquer pour ouvrir cette catégorie"
          >
            {this.formatCategoryAsText(category)}
          </a>
        </span>
        <div dangerouslySetInnerHTML={{ __html: formatHtmlHandlingImageTokens(html, slug, sourceForImagesInDevelopment) }} />
      </div>
    )
  }

  displayAndPublicationDates(params) {
    const {
      displayDate,
      publicationDate
    } = params

    if (displayDate) {
      return `Journée du ${this.formatDate(displayDate)}, publié le ${this.formatDate(publicationDate)} `
    } else {
      return `Publié le ${this.formatDate(publicationDate)} `
    }
  }

  formatDate(dateAsString) {
    const date = new Date(dateAsString)
    return date.toLocaleDateString()
  }

  formatCategoryAsText(category) {
    return category.replace("/", " ").replaceAll("-", " ")
  }

  formatCategoryAsUrl(category) {
    return `/${category}`
  }
}
