import React from "react"
import { Link } from "gatsby"

export default class BottomNavigation extends React.Component {
  render() {
    if (this.props.pageContext) return (
      <>
        <div>
          {this.#previousPage()}
          {this.#linkSeparator()}
          {this.#nextPage()}
        </div>
      </>
    )

    return null
  }

  #previousPage() {
    const {
      currentPage,
      category
    } = this.props.pageContext,
    targetUrl = category ? ((currentPage === 2) ? `/${category}` : `/${category}/page-${currentPage - 1}`) : ((currentPage === 2) ? "/" : `/page-${currentPage - 1}`)


    if (currentPage > 1) return (
      <Link
        to={targetUrl}
      >
        &lt;&lt;&nbsp;Page précédente
      </Link>
    )
    return ''
  }

  #nextPage() {
    const {
      currentPage,
      numPages,
      category
    } = this.props.pageContext,
    targetUrl = category ? (`/${category}/page-${currentPage + 1}`) : (`/page-${currentPage + 1}`)


    if (currentPage < numPages) return (
      <Link
        to={targetUrl}
      >
        Page suivante&nbsp;&gt;&gt;
      </Link>
    )
    return ''
  }

  #linkSeparator() {
    const {
      currentPage,
      numPages
    } = this.props.pageContext

    if (currentPage > 1 && currentPage < numPages) return (
      <>
        &nbsp;|&nbsp;
      </>
    )
  }
}
