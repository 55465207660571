import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <h1 style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          textDecoration: `none`
        }}
      >
        {siteTitle}
      </Link>
    </h1>
    <h2>
      <Link
        to="/categories"
        style={{
          textDecoration: `none`
        }}
      >Lecture par aventure
      </Link>
    </h2>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
